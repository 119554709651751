import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }), 
    animate('500ms ease-out', style({ opacity: 1 })), 
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('500ms ease-in', style({ opacity: 0 })),
  ]),
]);

export const shake_question = [
  trigger('shakeAnimation', [
    state('start', style({ transform: 'translateX(0)' })),
    state('end', style({ transform: 'translateX(-10px)' })),
    transition('start <=> end', [
      animate('0.1s ease-in-out'),
      animate('0.1s ease-in-out', style({ transform: 'translateX(10px)' })),
      animate('0.1s ease-in-out', style({ transform: 'translateX(0)' })),
    ]),
  ]),
]

export const fade_in_up_scale = [
  trigger('slideAnimation', [
    state('start', style({ transform: 'scale(1)' })),
    state('grow', style({ transform: 'scale(1.1)' })),
    state('shrink', style({ transform: 'scale(0.9)', opacity: 0 })),
    transition('start => grow', [animate('0.3s ease-in')]),
    transition('grow => shrink', [animate('0.3s ease-out')]),
    transition('shrink => start', [animate('0.1s')]),
  ]),
]

export const turbo_validate_identity = [
  trigger('turbo_validate_identity', [
    state('true', style({ display: 'none' })),
    transition('false => true', [
      animate('0.5s ease-in', style({ transform: 'translateX(10%)' })),
      animate('0.2s ease-out', style({ transform: 'translateX(-100%)' })),
      animate('0s', style({ display: 'none' })) 
    ])
  ]),
  trigger('enter_form_right', [
    state('void', style({ transform: 'translateX(100%)', opacity: 0 })),
    state('*', style({ transform: 'translateX(0)', opacity: 1 })),
    transition('void => *', [
      animate('0.5s ease-out')
    ])
  ])
];


export const top_position_normal = trigger('top_position_normal', [
  state('void', style({
    transform: 'translateY(100%)'
  })),
  state('*', style({
    transform: 'translateY(0)'
  })),
  transition('void => *', [
    animate('0.5s ease-in-out')
  ]),
  transition('* => void', [
    animate('0.5s ease-in-out')
  ])
])

export const slider_msg = trigger('slider_msg', [
  transition('hidden => visible', [
    style({ opacity: 0, transform: 'translateX(-5%)' }),
    animate('500ms ease-in', style({ opacity: 1, transform: 'translateX(0%)' }))
  ]),
  transition('visible => hidden', [
    style({ opacity: 1, transform: 'translateX(0%)' }),
    animate('500ms ease-out', style({ opacity: 0, transform: 'translateX(100%)' }))
  ])
])

export const fadeInOutAnimation = trigger('fadeInOut', [
    state('void', style({ opacity: 0 })),
    state('*', style({ opacity: 1 })),
    transition(':enter', animate('0.5s')),
    transition(':leave', animate(0)),
]);

export const fadeInOutAnimation_Leave = trigger('fadeInOut_Leave', [
  state('void', style({ opacity: 0 })),
  state('*', style({ opacity: 1 })),
  transition(':enter', animate('0.5s')),
  transition(':leave', animate('0.5s')),
]);

export const fadeInAnimation = trigger('fadeInAnimation', [
  transition(':enter', [
    animate('1s', keyframes([
      style({ opacity: 0, offset: 0 }),
      style({ opacity: 1, offset: 1 })
    ]))
  ]),
  transition(':leave', [
    animate('1s', keyframes([
      style({ opacity: 1, offset: 0 }),
      style({ opacity: 0, offset: 1 })
    ]))
  ])
]);

export const fadeInAnimationSuave = trigger('fadeInAnimationSuave', [
  transition(':enter', [
    style({ height: '0px' }), 
    animate('.2s ease-in', style({ height: '{{height}}' })) 
  ], { params: { height: '70px' } }),
  transition(':leave', [
    style({ height: '{{height}}' }), 
    animate('.2s ease-out', style({ height: '0px' })) 
  ], { params: { height: '70px' } }) 
]);

export const slideInAnimation = trigger('slideIn', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),
    animate('0.5s', style({ transform: 'translateX(0)' })),
  ]),
]);

export const sweepAnimation = trigger('sweep', [
    transition(':enter', [
      style({ transform: 'translateX(100%)' }),
      animate('500ms ease-out', style({ transform: 'translateX(0)' }))
    ])
]);

export const shakeAnimation = trigger('shake', [
  transition(':enter', [
    style({ transform: 'translateX(-5px)' }),
    animate('10.1s', style({ transform: 'translateX(5px)' })),
    animate('10.1s', style({ transform: 'translateX(-5px)' })),
    animate('10.1s', style({ transform: 'translateX(5px)' })),
    animate('10.1s', style({ transform: 'translateX(0)' })),
  ]),
]);

export const waterAnimation = trigger('waterAnimation', [
  state('initial', style({ height: '50px' })),
  state('maxHeight', style({ height: '70px' })),
  transition('* <=> *', animate('1s ease-in-out')),
]);

export const accordionAnimation = trigger('accordion', [
  state('expanded', style({ height: '*' })),
  state('collapsed', style({ height: '0', overflow: 'hidden' })),
  transition('expanded <=> collapsed', [animate('300ms ease-out')]),
])

export const mostrar = trigger('mostrar', [
  transition(':enter', [
    style({ opacity: 0.5 }),
    animate('2s', style({ opacity: 1 }))
  ])
])

export const fadeInOutAnimation_Show = trigger('fadeInOut_Show', [
  state('void', style({ opacity: 0 })),
  state('*', style({ opacity: 1 })),
  transition(':enter', animate('.5s')),
  transition(':leave', animate('.5s')),
]);