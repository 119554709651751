<div class="modal-body overflow-hidden" *ngIf="getCampaign">
    <div class="content-modal-theme">
        <div class="container-fluid px-0" id="">
            <div id="modalBody">
                <div id="">
                    <div class="py-1 px-2 position-relative">

                        <div class="alerts-message" *ngIf="sendForm" @fadeInAnimation>
                            <img [src]="env.S3_STATIC_FILES + '/images/web/' + (errorServices ? 'error-view' : 'success-view' ) +  '.svg'"
                                alt="Cambio de tasa" title="Cambio de tasa" height="160px" width="160px">

                            <h4 class="fw-bold mt-4">
                                @if (errorServices) {
                                Ha ocurrido un error al actualizar la nueva tasa de interés
                                } @else {
                                Estamos actualizando la tasa <br class="d-none d-md-block" /> de interés de tu CDT
                                }
                            </h4>

                            <p class="mt-3 mb-0">
                                @if (errorServices){
                                En unos segundos, podrás intentarlo nuevamente
                                } @else {
                                En unos segundos podrás continuar <br class="d-none d-md-block" /> con la apertura de tu
                                CDT
                                }
                            </p>
                        </div>

                        <div class="screenBloqe align-items-center d-grid" style=" justify-items: center;" *ngIf="screenBloqued">
                            <img [defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'"
                                [lazyLoad]="getCampaign.base_gif_load" width="70px" alt="">
                        </div>

                        <div class="text-center" id="content-body" style="opacity: {{ opacity }} " *ngIf="!sendForm">

                            <div class="col-12 mt-step">
                                <div class="bg-alert bg-blue-10 rounded-3 pt-1 pb-2">
                                    <img [lazyLoad]="env.S3_STATIC_FILES + '/images/web/ilustrations/alert-danger.webp'"
                                        alt="" class="border-0 alert-danger" width="150px">
                                </div>

                                <article class="mt-4">
                                    <h6 class="h5-size-title fw-bold">
                                        Cambio la tasa de tu CDT
                                    </h6>
                                    <p class="px-md-3 mt-4">

                                        Te informamos que la <b>tasa de interés de tu CDT ha sido actualizada</b>, ya
                                        que ha vencido el plazo en el que {{ name_bank_by_communication_components }}
                                        mantenía la <b>tasa del {{ data.rate_process }}%</b> para tu inversión.
                                        <br> <br>
                                        Para continuar con el proceso, te pedimos que <b>revises y confirmes</b> la
                                        aceptación de los <b>nuevos términos</b> antes de proceder con la firma de tu
                                        <b>CDT</b>.
                                    </p>
                                </article>

                                <div class="row justify-content-center my-3">
                                    <div class="col-12">
                                        <h3 class="h5-size-title fw-bold">
                                            Nueva tasa de interés del
                                            <span class="text-blue-100">{{ data.rate_simulate }}%</span>
                                        </h3>
                                    </div>

                                    <div class="col-10 mt-3 bg-blue-10 rounded-3 p-2">
                                        <div>
                                            <b>Entidad</b>: {{ name_bank_by_communication_components }}
                                        </div>
                                        <div>
                                            <b>Inversión</b>: ${{ data.amount_process | thousandSeparator }}
                                        </div>
                                        <div>
                                            <b>Plazo</b>: {{ data.terms_process }} {{ data.terms_process === 1 ? 'día' :
                                            'días' }}
                                        </div>
                                    </div>
                                </div>

                                <form [formGroup]="form" (ngSubmit)="onSubmit()"
                                    class="col-md-11 col-12 text-start d-grid justify-content-center ms-md-4 mx-0">
                                    <div class="box-terms col-md-12 col-auto d-flex justify-content-center align-items-center">
                                        <input type="checkbox" formControlName="acceptTerms" id="acceptTerms"
                                            class="custom-checkbox border-blue-100 custom-{{ getCampaign.name }}">
                                        <label for="acceptTerms" class="question-terms cursor-pointer">
                                            Acepto la nueva tasa de interés y los términos asociados.
                                        </label>
                                    </div>

                                    <div *ngIf="errorMessage" class="error-message">
                                        {{ errorMessage }}
                                    </div>

                                    <div class="btns-actions-invesment col-12 mt-3">
                                        <button type="submit" [disabled]="!form.valid" [ngClass]="{
                                            'border-blue-100 bg-blue-100 text-white': form.valid
                                        }" class="btn btn-accept-inv col-12">Continuar
                                        </button>
                                    </div>
                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>