import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@enviroment/environment';

@Injectable({
  providedIn: 'root'
})
export class ValidateRateChangingService {

  private domain: any;
  private endpoint: string = 'processes/validate-rate-changing?processId=';
  private api_key: any;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.domain = `${environment.API_BASE_URL}`;
    this.api_key = environment.API_KEY;
  }

  VALIDATE_RATE_CHANGING(idProcessUser: any, idToken: any): Promise<any> {
    if (isPlatformBrowser(this.platformId)) {
      return this.http.get(this.domain + this.endpoint + idProcessUser,
        {
          observe: "response",
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': `${this.api_key}`,
            'Authorization': `Bearer ${idToken}`
          })
        }
      ).toPromise()
        .then(this.extractData)
        .catch(this.extractData);
    }
    return Promise.reject('Server Side Rendering');
  }

  private extractData(res: any) {
    let body = res;
    return body;
  }
}