import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@enviroment/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateValuesProcessesService {

  private domain:any;
  private endpoint:string = 'processes/update-values';
  private api_key:any;


  constructor(
    private http:HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.domain = `${environment.API_BASE_URL}`;
    this.api_key = environment.API_KEY;
  }

  async UPDATE_VALUES_PROCESSES(body: any): Promise<any>{
    if(isPlatformBrowser(this.platformId)){
      return this.http.put(this.domain + this.endpoint, body.data,
        {
          observe: 'response',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': `${this.api_key}`,
            'Authorization': `Bearer ${body.idToken}`
          }) 
        }
      ).toPromise()
      .then(this.extractData) 
      .catch(this.extractData);
    }
    return Promise.reject('Server Side Rendering');
  }

  private extractData(res: any) {
    let body = res;
    return body;
  }
}
