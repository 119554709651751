import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@enviroment/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreDataService {
  private domain: any;
  private api_key: any;
  private endpoint: string = 'analytics/store-data';

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.domain = `${environment.API_BASE_URL}`;
    this.api_key = environment.API_KEY;
  }

  async STORE_DATA(objParamaters: any): Promise<any> {
    if (isPlatformBrowser(this.platformId)) {
      return this.http.post(this.domain + this.endpoint, objParamaters.data,
        {
          observe: 'response',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': `analytics-${this.api_key}`,
            'Authorization': `Bearer ${objParamaters.idToken}`
          })
        }
      ).toPromise()
        .then(this.extractData)
        .catch(this.extractData);
    }
    return Promise.reject('Server Side Rendering');
  }

  private extractData(res: any) {
    let body = res;
    return body;
  }
}

