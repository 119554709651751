import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StoreDataService } from '@apiShared/analytics/store-data/store-data.service';
import { fadeInOut } from '@class/animations/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieUtil } from '@utils/cookies';
import { UtilsMethods_Services } from '@utils/utils';

@Component({
  selector: 'app-modal-premiar-fidelidad',
  templateUrl: './modal-premiar-fidelidad.component.html',
  styleUrl: './modal-premiar-fidelidad.component.css',
  animations: [fadeInOut],
})
export class ModalPremiarFidelidadComponent {


  form!: FormGroup;
  itemActive: number = 0;
  loading: boolean = false;
  successForm: boolean = false;
  statusApi: number = 400;
  errorForm: boolean = false;
  openModalAnimation: boolean = true;
  items: any[] = [
    {
      icon: 'bi bi-percent',
      option: '<b class=" text-blue-100">Tasa extra</b> en tu próxima inversión de CDTs',
    },
    {
      icon: 'bi bi-currency-dollar',
      option: '<b class=" text-blue-100">Valor extra en dinero</b> para aumentar tu inversión',
    },
    {
      icon: 'bi bi-gift',
      option: '<b class=" text-blue-100">Tarjetas o bonos regalo</b> de tu almacén favorito',
    },
    {
      icon: 'bi bi-arrow-repeat',
      option: '<b class=" text-blue-100">Puntos</b> que puedas canjear fácilmente',
    },
    {
      icon: '',
      option: 'Otro, <b>¿cuál?</b>',
    }
  ]

  constructor(
    public activeModal: NgbActiveModal,
    public cookies: CookieUtil,
    public utils: UtilsMethods_Services,
    private fb: FormBuilder,
    private _api_storeData: StoreDataService
  ) {
    this.form = this.fb.group({
      option: [''],
      other: ['']
    });
  }

  activeItem(index: number) {
    this.errorForm = false;
    return this.itemActive = index
  }

  closeModal() {
    this.activeModal.close();
    this.cookies.save('mpf', 'true', 30);
  }

  submit() {
    const selectedOption = this.form.get('option')?.value;
    const otherValue = this.form.get('other')?.value;
    const idToken = this.cookies.get('id_token_user');
    let value: string = selectedOption;

    if(this.itemActive === 4) {
      value = otherValue;
    }
    
    if (!selectedOption || (this.itemActive === 4 === !otherValue)) {
      this.errorForm = true;
    } else {
      this.loading = true;
      this.errorForm = false;
      this._api_storeData.STORE_DATA(
        {
          data: {
            info_type: "prize_survey",
            content: {
              question: "¿Cuál sería la mejor forma de premiar tu fidelidad?",
              answer: this.utils.removeHtmlTags(value)
            }
          },
          idToken: idToken
        }
      ).then((data: any) => {
        const status = data.status;
        this.statusApi = status;
        this.loading = false;
        this.successForm = true;
        setTimeout(() => {
          this.closeModal();
        }, 6000);
      })
    }
  }

}