<div class="container-fluid bg-blue-40 fixed-top d-flex justify-content-center align-items-center ga_view_full_screen_alert" *ngIf="showView" [@fadeInOut]>
  <div class="container text-center">
    <div class="text-end text-blue-150 title-2 mb-4">
      <i class="bi bi-x-lg cursor-pointer ga_close_full_screen_alert" (click)="onClickClose()"></i>
    </div>
    @switch (typeModal) {
      @case ('magic-link') {
        <div class="text-center mb-4">
          <img
            [src]="env.S3_STATIC_FILES + '/images/web/qr-app-plazo-personalizado.png'"
            alt="Escanea el código con tu celular para simular con cualquier plazo"
            title="Escanea el código con tu celular para simular con cualquier plazo"
            class="w-25 rounded-4">
        </div>
        <h2 class="title-2 text-blue-150">
          Simula con el plazo que desees,
          <span class="text-blue-100">
            desde nuestra app.
          </span>
        </h2>
        <div class="text-size-lg text-blue-150 font-semibold my-4">
          ¡Escanea el QR y explóralo!
        </div>
      }
      @default {
        <div class="text-center mb-4" *ngIf="device.isDesktop">
          <img
            [src]="env.S3_STATIC_FILES + '/images/web/landings/app/qr-redirect-store.png'"
            alt="Escanea el código con tu celular para obtener la app"
            title="Escanea el código con tu celular para obtener la app"
            class="w-25 rounded-4">
        </div>
        <h2 class="title-2 text-blue-150">
          Abre tu CDT en los bancos más rentables,
          <span class="text-blue-100">
            gratis y sin salir de casa.
          </span>
        </h2>
        <div class="text-size-lg text-blue-150 font-semibold my-4">
          Descarga la app
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <div class="text-center" *ngFor="let store of storeOptionsContent">
            <a [href]="store.link" [class]="store.eventGA" target="_blank">
              <img
                [src]="env.S3_STATIC_FILES + store.logo"
                [alt]="'Descarga nuestra aplicación en la ' + store.name"
                [title]="'Descarga nuestra aplicación en la ' + store.name"
                class="w-75 {{ store.eventGA }}">
            </a>
          </div>
        </div>
      }
    }
  </div>
</div>
