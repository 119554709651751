// src/app/script-loader.service.ts

import { Injectable } from '@angular/core';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  loadScripts(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.addMetaPixelScript();
      this.addClarityScript();
    }
  }

  private addMetaPixelScript(): void {
    // Comprobar si el script ya está cargado
    if (!document.querySelector('script[src="https://connect.facebook.net/en_US/fbevents.js"]')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.innerHTML = `
        setTimeout(function() {
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1089973192821960');
          fbq('track', 'PageView');
        }, 3000);
      `;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=1089973192821960&ev=PageView&noscript=1"
      />`;
      document.body.appendChild(noscript);
    }
  }

  private addClarityScript(): void {
    // Comprobar si el script ya está cargado
    if (!document.querySelector('script[src^="https://www.clarity.ms/tag/"]')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.innerHTML = `
        setTimeout(function() {
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "7y04644usz");
        }, 3000);
      `;
      document.head.appendChild(script);
    }
  }

  /* Agregar Script de Google Tag Manager */

  loadGTM(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Verificar si el GTM script ya está en el head
      if (!document.querySelector('script[src^="https://www.googletagmanager.com/gtm.js"]')) {
        this.addGTM();
      }

      // Verificar si el iframe 'noscript' ya está en el body
      if (!document.querySelector('noscript iframe[src^="https://www.googletagmanager.com/ns.html"]')) {
        this.addNoscript();
      }
    }
  }

  // Agregar el script de GTM al head
  private addGTM() {
    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = `
    (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-MZFSNS2');
    `;
    document.head.appendChild(script);
  }

  // Agregar el iframe 'noscript' al body
  private addNoscript() {
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MZFSNS2" height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noscript);
  }
}
