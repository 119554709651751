import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GtmService {

    constructor() { }

    /**
     * Dispara un evento en Google Tag Manager (GTM)
     * @param event Nombre del evento
     * @param componentName Nombre del componente que lo dispara
     */
    triggerGTMEvent(event: string, componentName: string): void {
        if (typeof window !== 'undefined' && window['dataLayer']) {
            window['dataLayer'].push({
                event,
                componentName,
                timestamp: new Date().toISOString()
            });
        } else {
            console.warn('dataLayer no está definido.');
        }
    }
}
