import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@enviroment/environment';
import { DeviceService } from "@apiShared/device/device.service";
import { fadeInOutAnimation } from '@class/animations/animations';

@Component({
  selector: 'app-modal-download-app-fullscreen',
  templateUrl: './modal-download-app-fullscreen.component.html',
  styleUrl: './modal-download-app-fullscreen.component.css',
  animations: [fadeInOutAnimation]
})
export class ModalDownloadAppFullscreenComponent implements OnInit {
  @Input() typeModal!: string

  showView: boolean         = true;
  env                       = environment;
  storeOptionsContent: any  = [
    {
      logo  : '/images/web/logo-app-apple.webp',
      link  : 'https://apps.apple.com/co/app/mejorcdt/id6451003606',
      name  : 'App Store',
      eventGA : 'ga_aps_full_screen_alert'
    },
    {
      logo  : '/images/web/logo-app-android.webp',
      link  : 'https://play.google.com/store/apps/details?id=com.mejorcdt.app',
      name  : 'Play Store',
      eventGA : 'ga_gop_full_screen_alert'
    }
  ];

  constructor(
    public device: DeviceService,
  ) {}

  ngOnInit(): void {
    if (!this.device.isDesktop && !this.typeModal) {
      if (this.device.isIOS) {
        this.storeOptionsContent.splice(1, 1);
      }

      if (this.device.isAndroid) {
        this.storeOptionsContent.splice(0, 1);
      }
    }
  }

  onClickClose(): void {
    this.showView = false;
  }

}
