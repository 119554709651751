import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Banks from '../../utils/json/banks.json'
import { UpdateProcessStatusService } from '@apiShared/process-status/update/update-process-status.service';
import { CookieUtil } from '@utils/cookies';
import { Globals } from '@config/global';
import { environment } from '@enviroment/environment';
import { getDataCampaign } from '@utils/get-campaign';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UpdateValuesProcessesService } from '@apiShared/process-status/update-values/update-values-processes.service';
import { fadeInAnimation } from '@class/animations/animations';


@Component({
  selector: 'app-modal-new-signing-conditions',
  templateUrl: './modal-new-signing-conditions.component.html',
  styleUrls: ['./modal-new-signing-conditions.component.css'],
  animations: [fadeInAnimation]
})
export class ModalNewSigningConditionsComponent implements OnInit {

  env = environment;
  data: any;
  name_bank_by_communication_components: any;

  screenBloqued: any = false;
  opacity: any;
  successMessage: boolean = false;
  errorServices: boolean = false;
  sendForm: boolean = false;

  form!: FormGroup;
  errorMessage: string | null = null;
  getCampaign: any = {};

  constructor(
    public modal: NgbActiveModal,
    public cookies_util: CookieUtil,
    public globals_var: Globals,
    public _getDataCampaign: getDataCampaign,
    private fb: FormBuilder,
    private _put_update_valuyes_process: UpdateValuesProcessesService
  ) {
    this.getDataCampaign();
    this.getControlsForm();
  }

  ngOnInit(): void {
    this.bankName_Translator(this.data.bank_id);
  }

  getDataCampaign() {
    this._getDataCampaign.getCampaignDataUpdates().subscribe(data => {
      this.getCampaign = data;
    });
  }

  getControlsForm() {
    this.form = this.fb.group({
      acceptTerms: [false, Validators.requiredTrue]
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.errorMessage = null;
      this.updateInvesment();
    } else {
      this.errorMessage = 'Por favor, es necesario que aceptes la nueva tasa de interés y los términos asociados';
    }
  }

  bankName_Translator(bank_id: any) {
    return this.name_bank_by_communication_components = Banks['raw'][bank_id]['bank'].replace(this.globals_var.FLAMINGO, "Coltefinanciera")
  }

  updateInvesment() {
    this.screenBloqued = true;
    this.opacity = .5;

    let body = {
      data: {
        uuid: this.cookies_util.get('id_process'),
        fields: {
          rate: String(this.data.rate_simulate)
        }
      },
      idToken: this.cookies_util.get('id_token_user')
    }

    this._put_update_valuyes_process.UPDATE_VALUES_PROCESSES(body).then((data: any) => {
      this.screenBloqued = false;
      this.sendForm = true;
      this.opacity = 1;

      if (data.status === 200 || data.status === 201) {
        this.successMessage = true;
      } else {
        this.errorServices = true;
      }

      setTimeout(() => {
        location.reload();
      }, 4000);
    });
  }
}
