<div class="position-relative bg-white rounded-3" *ngIf="getCampaign">
    <div *ngIf="screen_desktop == true" class="screenBloqe align-items-center" style=" justify-items: center;"
        [ngClass]="
        {
            'd-none': screenBloqued == false,
            'd-grid' : screenBloqued == true
        }
        ">
        <img [defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" [lazyLoad]="getCampaign.base_gif_load" width="70px" alt="">
    </div>

    <div class="modal-body px-4 py-5" style="opacity: {{ opacityLoadGetProcess }} " *ngIf="screen_desktop == true" >
        <div class="content-modal-theme">
            <div class="container-fluid px-0">
                <div id="modalBody">
                    <button
                    class="btn-close-all-process text-white"
                    [ngClass]="
                        {
                        'd-block': (number_process === 0 && (step_notificacion_components !== 2 && step_notificacion_components !== 7)) || error_activate,
                        'd-none': !((number_process === 0 && (step_notificacion_components !== 2 && step_notificacion_components !== 7)) || error_activate)
                        }"
                    (click)="activeModal.dismiss()" >
                        <i class="bi bi-x-lg fw-bold"></i>
                    </button>
                    <div id="info-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="w-100 px-2">
                                    <div *ngIf="number_process >= 1">
                                        <h3 class="fw-bold lh-1">
                                            <span class="text-blue-100">¡Hey,  {{ searchCookie('fullname') }}!</span> Vemos que tienes CDT pendientes por apertura.
                                        </h3>

                                        <p class="mt-3">
                                        <font class="text-blue-100 fw-bold"> A continuación te mostraremos</font> todos tus procesos actuales, y podrás escoger con cuál continuar 📌
                                        </p>
                                    </div>

                                    <div *ngIf="(number_process === 0) && (step_notificacion_components != 2 || step_notificacion_components != 7)">

                                        <h3 class="fw-bold lh-1">
                                            <span class="text-blue-100">Actualmente no tienes procesos activos.</span>
                                        </h3>

                                        <p class="mt-3">
                                            Te invitamos a que simules y abras tu cdt totalmente grátis <a [routerLink]="['/cdt-simulador']" routerLinkActive="active" (click)="activeModal.dismiss()">desde aquí</a>
                                        </p>
                                    </div>

                                    <div *ngIf="(number_process == 0) && (step_notificacion_components == 2 || step_notificacion_components == 7)">
                                        <h3 class="fw-bold lh-1">
                                            <span class="text-blue-100">Para continuar abriendo tu cdt,</span>
                                        </h3>

                                        <p class="mt-3">
                                            es necesario que la información de los parametros de tu inversión sean correctos, una vez lo hayas validado, podrás continuar <b>abriendo tu cdt</b>
                                        </p>
                                    </div>

                                    <div class="col-12 px-2" *ngIf="error_activate">
                                        <h6 class="fw-bold text-danger">¡Hubo un error inesperado!</h6>
                                        <p class="text-danger">
                                            {{ error_text }}
                                        </p>
                                    </div>

                                    <div class="position-relative process-id process-shadow proceso-actual" *ngIf="step_notificacion_components == 2 || step_notificacion_components == 7">
                                        <div class="process-date text-blue-100">
                                            {{ getDateLyric(date_day) }}
                                        </div>
                                        <div class="process-recient text-blue-100 position-absolute">
                                            Proceso actual
                                        </div>
                                        <div class="row justify-content-between align-items-center mx-0 process-user-detail"
                                        >
                                            <div class="col-auto">
                                                <div class="text-blue-100 fw-bold">
                                                    Entidad:
                                                </div>
                                                <div>
                                                    {{ bank_name_simulation }}
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <div class="text-blue-100 fw-bold">
                                                    Monto:
                                                </div>
                                                <div>
                                                    ${{ getThousendFormatView(bank_amount_simulation) }}
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <div class="text-blue-100 fw-bold">
                                                    Plazo:
                                                </div>
                                                <div>
                                                    {{ bank_term_simulation }} {{ bank_term_simulation === 1 ? 'día' : 'días' }}
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <div class="text-blue-100 fw-bold">
                                                    Tasa:
                                                </div>
                                                <div>
                                                    {{ bank_rate_simulation }}%
                                                </div>
                                                <div class="tag-rate-referral bg-blue-100 text-white rounded-3" *ngIf="show_tag_referred">
                                                  ⦿&nbsp;<b> <span>+</span>0,10%</b>
                                                </div>
                                            </div>
                                            <div class="col-2 ps-0 pe-1">
                                                <button class="w-100 px-1 btn border-tema bnt-process-reciente border-0"
                                                    (click)="ClickCreateProcess()">Abre tu CDT
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="aperturas-antiguas" *ngIf="number_process >= 1 && (step_notificacion_components == 7 || step_notificacion_components == 2)">
                                        Aperturas antiguas
                                    </div>
                                    <div class="position-relative process-id" *ngFor="let allProcess of jsonAllProcessUser"
                                    [ngClass]="
                                            {
                                                'mb-process-first process-shadow': allProcess.position == 0 && step_notificacion_components !== 7,
                                                'margin-process-ant': allProcess.position !== 0
                                            }
                                        "
                                    >
                                        <div class="process-date text-blue-100"[ngClass]="
                                            {
                                                'process-bg-active fw-bold': allProcess.position == 0 && step_notificacion_components !== 2 && step_notificacion_components !== 7,
                                                '': allProcess.position !== 0
                                            }
                                        ">
                                            {{ getDateLyric(allProcess.updated_at) }}
                                        </div>
                                        <div class="process-recient position-absolute" *ngIf="allProcess.position == 0 && step_notificacion_components !== 2 && step_notificacion_components !== 7">
                                            Proceso reciente {{ (allProcess).length }}
                                        </div>
                                        <div class="row justify-content-evenly align-items-center mx-0 process-user-detail"
                                        >
                                            <div class="col-auto ps-1" placement="top">
                                                <div ngbTooltip="{{ jsonProcessStatus['raw'][allProcess.process_status]['info_help'] }}">
                                                    <div class="text-blue-100 fw-bold">
                                                        Proceso:
                                                    </div>
                                                    <div class="steep_process {{ getCampaign.components.form_minutes_open_cdt.btn_color_form }}">
                                                    {{ jsonProcessStatus['raw'][allProcess.process_status]['paso'] }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <div class="text-blue-100 fw-bold">
                                                    Entidad:
                                                </div>
                                                <div>
                                                    {{ jsonBanks['raw'][allProcess.bank_id]['bank'].replace(globals_var.FLAMINGO, globals_var.COLTEFINANCIERA) }}
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <div class="text-blue-100 fw-bold">
                                                    Monto:
                                                </div>
                                                <div>
                                                    ${{ getThousendFormatView(allProcess.amount) }}
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <div class="text-blue-100 fw-bold">
                                                    Plazo:
                                                </div>
                                                <div>
                                                    {{ allProcess.term }} {{  allProcess.term === 1 ? 'día' : 'días'  }}
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <div class="text-blue-100 fw-bold">
                                                    Tasa:
                                                </div>
                                                <div>
                                                    {{ allProcess.rate }}%
                                                </div>
                                            </div>
                                            <div class="col-2 ps-0 pe-1">
                                                <button class="w-100 px-2 btn border-tema"
                                                [ngClass]="
                                                    {
                                                        'bnt-process-reciente border-0': allProcess.position == 0 && step_notificacion_components !== 7,
                                                        'btn-firs-minute-cdt': allProcess.position == 0 && step_notificacion_components == 7,
                                                        'btn-process-all' : allProcess.position !== 0
                                                    }
                                                " (click)="clickGetProcess(allProcess.uuid)">Continuar con tu CDT</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="screen_mobile == true" class="all_process_mobile bg-white opacity-100">
        <div *ngIf="screen_mobile == true" class="screenBloqe align-items-center" style=" justify-items: center;"
            [ngClass]="
                {
                    'd-none': screenBloqued == false,
                    'd-grid' : screenBloqued == true
                }
                ">
                <img [defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" [lazyLoad]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" alt="">
            </div>
            <div class="all_process" style="opacity: {{ opacityLoadGetProcess }} ">

                <button class="d-none btn-close-all-process" (click)="activeModal.dismiss()">
                    <i class="bi bi-x-lg fw-bold"></i>
                </button>

                <div *ngIf="number_process >= 1">
                    <h3 class="fw-bold lh-1">
                        <span class="text-blue-100">¡Hey,  {{ searchCookie('fullname') }}!</span> Vemos que tienes CDT pendientes por apertura.
                    </h3>

                    <p class="mt-3">
                    <font class="text-blue-100 fw-bold"> A continuación te mostraremos</font> todos tus procesos actuales, y podrás escoger con cuál continuar 📌
                    </p>
                </div>

                <div *ngIf="(number_process == 0) && (step_notificacion_components == 2 || step_notificacion_components == 7)">
                    <h3 class="fw-bold lh-1">
                        <span class="text-blue-100">Para continuar abriendo tu cdt,</span>
                    </h3>

                    <p class="mt-3">
                        es necesario que la información de los parametros de tu inversión sean correctos, una vez lo hayas validado, podrás continuar <b>abriendo tu cdt</b>
                    </p>
                </div>


                <div class="col-12 px-0" *ngIf="error_activate">
                    <h6 class="fw-bold text-danger">¡Hubo un error inesperado!</h6>
                    <p class="text-danger">
                        {{ error_text }}
                    </p>
                </div>


                <div class="position-relative process-id process-shadow proceso-actual" *ngIf="step_notificacion_components == 2 || step_notificacion_components == 7">
                    <div class="process-date text-blue-100">
                        {{ getDateLyric(date_day) }}
                    </div>
                    <div class="process-recient position-absolute">
                        Proceso actual
                    </div>
                    <div class="row mx-0 justify-content-between align-items-center mx-0 process-user-detail">
                        <div class="col-6">
                            <div class="col-auto">
                                <div class="text-blue-100 fw-bold">
                                    Entidad:
                                </div>
                                <div>
                                    {{ bank_name_simulation }}
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="text-blue-100 fw-bold">
                                    Monto:
                                </div>
                                <div>
                                    ${{ getThousendFormatView(bank_amount_simulation) }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6 text-end">
                            <div class="col-auto">
                                <div class="text-blue-100 fw-bold">
                                    Plazo:
                                </div>
                                <div>
                                    {{ bank_term_simulation }} {{  bank_term_simulation === 1 ? 'mes' : 'meses'  }}
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="text-blue-100 fw-bold">
                                    Tasa:
                                </div>
                                <div>
                                    {{ bank_rate_simulation }}%
                                </div>
                                <div class="tag-rate-referral-mobile bg-blue-100 text-white text-size-sm rounded-3" *ngIf="show_tag_referred">
                                  <b><span>+</span>&nbsp;0,10 %</b>&nbsp;⦿
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <button class="w-100 px-0 btn border-tema bnt-process-reciente border-0"
                                (click)="ClickCreateProcess()">Abre tu CDT
                            </button>
                        </div>
                    </div>
                </div>

                <div class="aperturas-antiguas" *ngIf="number_process >= 1 && (step_notificacion_components == 2 || step_notificacion_components == 7)">
                    Aperturas antiguas
                </div>


                <div class="position-relative process-id" *ngFor="let allProcess of jsonAllProcessUser"
                [ngClass]="
                        {
                            'mb-process-first process-shadow': allProcess.position == 0 ,
                            'margin-process-ant': allProcess.position !== 0
                        }
                    "
                >
                    <div class="process-date text-blue-100"[ngClass]="
                        {
                            'process-bg-active fw-bold': allProcess.position == 0 && step_notificacion_components !== 2,
                            '': allProcess.position !== 0
                        }
                    ">
                        {{ getDateLyric(allProcess.updated_at) }}
                    </div>
                    <div class="process-recient text-blue-100 position-absolute" >
                        {{ jsonProcessStatus['raw'][allProcess.process_status]['paso'] }}
                    </div>
                    <div class="row mx-0  justify-content-evenly align-items-center mx-0 process-user-detail"
                    >

                        <div class="col-6">
                            <div class="col-auto">
                                <div class="text-blue-100 fw-bold">
                                    Entidad:
                                </div>
                                <div>
                                    {{ jsonBanks['raw'][allProcess.bank_id]['bank'].replace(globals_var.FLAMINGO, globals_var.COLTEFINANCIERA) }}
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="text-blue-100 fw-bold">
                                    Monto:
                                </div>
                                <div>
                                    ${{ getThousendFormatView(allProcess.amount) }}
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="col-auto">
                                <div class="text-blue-100 fw-bold">
                                    Plazo:
                                </div>
                                <div>
                                    {{ allProcess.term }} {{  allProcess.term === 1 ? 'mes' : 'meses'  }}
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="text-blue-100 fw-bold">
                                    Tasa:
                                </div>
                                <div>
                                    {{ allProcess.rate }}%
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-2">
                            <button class="w-100 px-0 btn border-tema"
                            [ngClass]="
                                {
                                    'bnt-process-reciente border-0': allProcess.position == 0,
                                    'btn-process-all' : allProcess.position !== 0
                                }
                            " (click)="clickGetProcess(allProcess.uuid)">Continuar con tu CDT</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
