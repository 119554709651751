<div class="modal-content position-relative" [@fadeInOut] *ngIf="openModalAnimation">
    <span class="close position-absolute title-4 cursor-pointer" (click)="closeModal()"
        *ngIf="!loading && !successForm">
        <i class="bi bi-arrow-left-short"></i>
    </span>

    <div class="load" *ngIf="loading && !successForm">
        <div class="spinner-border text-blue-100" role="status">
        </div>
    </div>

    <div class="messagge-success" *ngIf="!loading && successForm">

        <div *ngIf="[200, 202].includes(statusApi)">
            <iframe src="https://lottie.host/embed/64b1d906-93bf-4640-9275-eb9ce3f192d9/a1PUZhK1j2.lottie"></iframe>
            <p class="text-center text-secondary">
                Hemos guardando tu respuesta. <br> ¡Gracias por tu tiempo!
            </p>
        </div>


        <div *ngIf="![200, 202].includes(statusApi)" class="text-danger text-center">
            Ha sucedido un error al guardar tu respuesta. 
        </div>
    </div>


    <div class="form-agradecimiento" *ngIf="!loading && !successForm">
        <div class="modal-header px-0 align-items-center justify-content-center border-0 mt-4">
            <h2 class="modal-title fw-bold text-center title-5" id="feedbackModalLabel">
                Queremos agradecer tu confianza. <br class="d-none d-md-block"> ¿Cuál sería la mejor forma de premiar
                <br class="d-none d-md-block"> tu fidelidad?
            </h2>
        </div>
        <p class="text-center fw-normal text-size-md mb-0">
            Elige la opción que más te guste:
        </p>
        <div class="modal-body border-0">
            <form [formGroup]="form">
                <div class="form-check px-0 mb-3" *ngFor="let item of items; let i = index">
                    <input class="form-check-input px-0 d-none" type="radio" formControlName="option" id="option-{{i}}"
                        value="{{ item.option }}">
                    <label class="form-check-label cursor-pointer col-12" for="option-{{i}}" (click)="activeItem(i)">
                        <i class="{{ item.icon }}  text-blue-100"></i> <span [innerHTML]="item.option"></span>
                    </label>

                </div>
                <!-- Otra opción, ¿cuál? -->
                <div class="form-check px-0 mb-3" *ngIf="itemActive == 4">
                    <input type="text" class="form-control mt-2" formControlName="other"
                        placeholder="Especifica otra opción">
                </div>
            </form>

            <div class="alert alert-danger my-2" role="alert" *ngIf="errorForm">
                Para enviar tu respuesta, debes
                @if (itemActive === 4 === !form.get('other')?.value) {
                especificar otra
                } @else {
                seleccionar una
                }
                opción.
            </div>
        </div>

        <div class="modal-footer border-0 pt-0 justify-content-center">
            <button type="button" class="btn bg-green col-12 col-md-12 text-white fw-bold py-2" (click)="submit()">
                Enviar
            </button>
        </div>
    </div>
</div>